import React from 'react';
import classNames from 'classnames';
import Header from '../../../layout/Header/Header';
import CommonHeaderRight from './CommonHeaderRight';
import { getRequester } from '../../../helpers/helpers';

const PageLayoutHeader = () => {
	const { person_name } = getRequester();

	return (
		<Header>
			<div className={classNames('header-left', 'col-md')}>
				<h4>Hi, {person_name}!</h4>
			</div>
			<CommonHeaderRight />
		</Header>
	);
};

export default PageLayoutHeader;
