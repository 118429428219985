import axios from 'axios';
import authHeader from '../../auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;

const read = async (nik) => {
	return axios.get(`${API_URL_DEFAULT}employee/${nik}`, {
		headers: await authHeader(),
	});
};

export default { read };
