import EmployeeModule from '../../../services/v2/handri/employee.service';

const read = (nik) => {
	return EmployeeModule.read(nik).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

export default { read };
